<script>
import { required, email } from "vuelidate/lib/validators";

import {
  authMethods,
  authFackMethods,
  notificationMethods
} from "@/state/helpers";
import router from "../../../router";

export default {
  data() {
    return {
      user:{
        username:'',
        password:'',
        loginProvince:'陕西省',
        loginCity:'咸阳市',
        loginLat:34.27, //纬度
        loginLng:108.08, //经度
      },
      email: "",
      password: "",
      submitted: false
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  created() {
    document.getElementById("layout-header").hidden = true;
    document.getElementById("layout-footer").hidden = true;
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    email: { required, email },
    password: { required }
  },
  methods: {
    async getLocalCity(){
      let data = {
        key: 'IUTBZ-UHAKU-PD6VI-BZEEY-N3YT3-SCB6J',
        output:'jsonp'
      }
      let url = 'https://apis.map.qq.com/ws/location/v1/ip'
      // console.log(this.$jsonp(url, data))
      const res = await this.$jsonp(url, data)
      return res
    },
    toast(variant, message) {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Toaster`,
        toaster: 'b-toaster-top-left',
        variant: variant,
        solid: true,
        appendToast: false
      });
    },
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    async tryToLogIn() {
      // eslint-disable-next-line no-debugger

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.password = this.$md5(this.password);
        let local = await this.getLocalCity()

        this.user.loginProvince = local.result.ad_info.province
        this.user.loginCity = local.result.ad_info.city
        this.user.loginLat = local.result.location.lat
        this.user.loginLng = local.result.location.lng
        this.user = {
          loginProvince : local.result.ad_info.province,
          loginCity : local.result.ad_info.city,
          loginLat : local.result.location.lat,
          loginLng : local.result.location.lng,
          email: this.email,
          userPwd: this.password
        }
        const {data: res} = await this.$blog.post('/login', this.user);
        if (res.code == 200) {

          window.sessionStorage.setItem("token", JSON.stringify(res.data.token));
          window.sessionStorage.setItem("user", JSON.stringify(res.data.user));
          // this.$store.commit('getUserInfo')
          // this.$store.commit('cancelLFV')
          if (res.data.user.userType == 1) {
            router.push('/admin');
          } else if (res.data.user.userType == 2) {
            router.push('/');
          }

        } else {
          this.toast(res.success?'success':'danger',res.msg);
        }


        // if (res.code !== 200) {
        //   return this.$message({message: '用户名或密码错误', type: 'error', offset: 80})
        // }else {
        //   this.toast('b-toaster-bottom-left');
        // }
        // this.$message({message: '登录成功', type: 'success', offset: 80});
        // this.$refs.loginFormRef.resetFields()

      }
    },

  },

};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">

          <div class="col-lg-12">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>

              <div class="col-lg-4 " style="background-color: white;margin: auto;" >
                <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div class="w-100" >
                    <div class="row justify-content-center" >
                      <div class="col-lg-9">
                        <div>
                          <div class="text-center">
                            <div>
                              <a href="/" class="logo">
                                <img src="@/assets/logo.png" height="20" alt="logo" />
                              </a>
                            </div>

                            <h4 class="font-size-18 mt-4">Welcome Back !</h4>
                            <p class="text-muted">Sign in to continue to memory.</p>
                          </div>

                          <b-alert
                              variant="danger"
                              class="mt-3"
                              v-if="notification.type == 'danger'"
                              show
                              dismissible>{{notification.message}}</b-alert>

                          <b-alert
                              variant="success"
                              class="mt-3"
                              v-if="notification.type == 'success'"
                              show
                              dismissible>{{notification.message}}</b-alert>

                          <div class="p-2 mt-5">
                            <form class="form-horizontal" @submit.prevent="tryToLogIn">
                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="ri-mail-line auti-custom-input-icon"></i>
                                <label for="email">Email</label>
                                <input
                                    type="email"
                                    v-model="email"
                                    class="form-control"
                                    id="email"
                                    placeholder="Enter email"
                                    :class="{ 'is-invalid': submitted && $v.email.$error }"
                                />
                                <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                                  <span v-if="!$v.email.required">Email is required.</span>
                                  <span v-if="!$v.email.email">Please enter valid email.</span>
                                </div>
                              </div>

                              <div class="form-group auth-form-group-custom mb-4">
                                <i class="ri-lock-2-line auti-custom-input-icon"></i>
                                <label for="userpassword">Password</label>
                                <input
                                    v-model="password"
                                    type="password"
                                    class="form-control"
                                    id="userpassword"
                                    placeholder="Enter password"
                                    :class="{ 'is-invalid': submitted && $v.password.$error }"
                                />
                                <div
                                    v-if="submitted && !$v.password.required"
                                    class="invalid-feedback"
                                >Password is required.</div>
                              </div>

                              <div class="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="customControlInline"
                                />
                                <label
                                    class="custom-control-label"
                                    for="customControlInline"
                                >Remember me</label>
                              </div>

                              <div class="mt-4 text-center">
                                <button
                                    class="btn btn-primary w-md waves-effect waves-light"
                                    type="submit"
                                >Log In</button>
                              </div>

                              <div class="mt-4 text-center">
                                <router-link tag="a" to="/forgot-password" class="text-muted">
                                  <i class="mdi mdi-lock mr-1"></i> Forgot your password?
                                </router-link>
                              </div>
                            </form>
                          </div>

                          <div class="mt-5 text-center">
                            <p>
                              Don't have an account ?
                              <router-link
                                  tag="a"
                                  to="/register"
                                  class="font-weight-medium text-primary"
                              >Register</router-link>
                            </p>
                            <p>
                              © 2022 memory. Crafted with
                              <i class="mdi mdi-heart text-danger"></i> by Memory
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>